html,
body {
  background-color: black;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  display: inline-block;
  text-align: center;
  padding: 0;
  margin: 0;
}

.page {
  display: inline-flex;
  width: 100vw;
  height: 100vh;
  color: white;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image {
  width: 250px;
  max-width: 30vw;
  height: auto;
}